<template>
  <div
    id="layout"
    class="antialiased bg-white text-stone-900 font-sans border-b"
  >
    <client-only>
      <Sidebars />
    </client-only>

    <header>
      <ErrorBoundary>
        <Navbar />
      </ErrorBoundary>
      <slot name="banner" />
    </header>

    <ErrorBoundary>
      <main>
        <PageLoader />
        <slot />
      </main>
    </ErrorBoundary>

    <slot name="links-section">
      <ErrorBoundary>
        <NuxtLazyHydrate when-visible>
          <EventListLinksBlock />
        </NuxtLazyHydrate>
      </ErrorBoundary>
    </slot>
    <slot name="footer">
      <ErrorBoundary>
        <NuxtLazyHydrate when-visible>
          <Footer :footer-block-items="footerBlock" />
        </NuxtLazyHydrate>
      </ErrorBoundary>
    </slot>
  </div>
</template>
<script lang="ts" setup>
const { locale } = useI18n()

const { data: footerData } = await useStrapiSingleType(
  `footer?locale=${locale.value}&populate[footerBlock][populate]=*`
)

const { setupValidationPolling, deactivateValidationPolling } =
  useCartValidation()

const { footerBlock = [] } = footerData?.value?.data?.attributes || {}

onMounted(async () => {
  const { useGtmPageView } = await import('~/composables/useGtmPageView')
  useGtmPageView()
  setupValidationPolling()
})

onUnmounted(() => {
  deactivateValidationPolling()
})

useLayoutHead()
</script>

<style lang="postcss" scoped>
#layout {
  box-sizing: border-box;
}

header {
  @apply sticky top-0 z-20;
}

main {
  min-height: 100vh;
  position: relative;
}
</style>
